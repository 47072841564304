import prettyMilliseconds from 'pretty-ms';
import { green, red, yellow } from '@ant-design/colors';
import { useEffect, useState, useSyncExternalStore } from 'react';

export function msFormatter(ms) {
  if (!Number.isFinite(ms)) {
    return "N/A"
  }
    return <span style={{color: getMsColor(ms)}}>{prettyMilliseconds(ms)}</span>
}

export function getMsColor(ms){
    if (ms < 10 * 1000){ // 10 sec
        return "#389e0d" // green
    }
    else if (ms < 3 * 60 * 1000){ // 2 min
        return "#fa8c16" // orange
    }
    else {
        return "#f5222d" // red
    }

}
var ts = Math.round(new Date().getTime() / 1000);
var ts1H = (ts - (1 * 3600)) * 1000;
var tsYesterday = (ts - (24 * 3600)) * 1000;
var ts7D = (ts - (24 * 3600 * 7)) * 1000;
var ts30D = (ts - (24 * 3600 * 30)) * 1000;

let date1H = new Date(ts1H)
let date24hAgo = new Date(tsYesterday)
let date7D = new Date(ts7D)
let date30D = new Date(ts30D)


export const AVAILABLE_PERIODS = { // -1 = all period
    "Last Hour": {
      value: "1H",
      lowerDate: date1H,
      label: "hour",
    },
    "1D": {
      value: "1D",
      lowerDate: date24hAgo,
      label: "24h",
    },
    "7D": {
      value: "7D",
      lowerDate: date7D,
      label: "7 days",
    },
    "1M": {
      value: "1M",
      lowerDate: date30D,
      label: "30 days",
    },
    "All": {
      value: "all",
      lowerDate: new Date(0),
      label: "All",
    },
    // "Custom": {
    //   value: "custom",
    //   lowerDate: new Date(0),
    // },
  }

  export async function fetcher(resource, init){
    return fetch(process.env.REACT_APP_API + resource, init).then(res => res.json()).then(res => {
    if (res.error) {
      throw new Error(res.error);
    }
    return res;
  });
  }
  export async function fetcherWS(resource, init){
    return fetch(process.env.REACT_APP_WS_HTTP + resource, init).then(res => res.json()).then(res => {
    if (res.error) {
      throw new Error(res.error);
    }
    let parsed = []

    parsed = [
      ...res.map((d) => ({ ts: d.ts, value: d.bps, cat: "bps" })), 
      ...res.map((d) => ({ ts: d.ts, value: d.cps, cat: "cps" }))
    ]
    parsed.sort((a, b) => a.ts - b.ts)

    return parsed;
  });
  }


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  export  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  