  import React from 'react';
  import { Col, Row, Table, Typography } from 'antd';
import useSWR from 'swr';

  const columns = [
    {
      title: 'Date (local)',
      dataIndex: 'createdAt',
      key: 'created',
        render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Max CPS',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const columnsBPS = [
    {
      title: 'Date (local)',
      dataIndex: 'createdAt',
      key: 'created',
        render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Max BPS',
      dataIndex: 'value',
      key: 'value',
    },
  ];


const MaxCPSTable = ({last24h = false}) => {
    const { data, error, isLoading } = useSWR(`/max${last24h ? "24h": ""}-history`)
    if (isLoading) return <div>Loading...</div>
    return (
        <>
        <Typography.Title level={3}>Max CPS/BPS {last24h ? "last 24h" : "All time"}</Typography.Title>
        <Row justify="center" align="top" gutter={{  md: 24, lg: 32 }}>
        <Col xs={24} lg={12}>
        <Table size='small' columns={columns} dataSource={data.cps} pagination={false} />
        </Col>
        <Col xs={24} lg={12}>
        <Table size='small' columns={columnsBPS} dataSource={data.bps} pagination={false} />
        </Col>
    </Row>
        </>
    );
};

  export default MaxCPSTable;
   