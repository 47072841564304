import React, { useState, useEffect } from 'react';
import { DualAxes, Line } from '@ant-design/charts';
import { Card, Segmented, Space, Statistic, Tooltip } from 'antd';
import prettyMilliseconds from 'pretty-ms';
import useSWR from 'swr'
import { AVAILABLE_PERIODS, useWindowDimensions } from '../utils';


function ConfTimesChart({ period, setScale, scale }) {
  // Use the useEffect hook to fetch the /conf-times data when the component is mounted
  const { data, error, isLoading } = useSWR(`/conf-times?period=${AVAILABLE_PERIODS[period].value}`)
  const { data: max, error: errorMax, isLoading: isLoadingMax } = useSWR(`/max`)
  const { height, width } = useWindowDimensions();

  if (error) return <div>Failed to load. Please try again in few seconds.</div>

  // remove element containing ts = null
  if (data !== undefined) {
    data.forEach((element, index) => {
      if (element.ts === null) {
        data.splice(index, 1)
      }
    });
  }

  let format
  let diff
  console.log({ diff });
  if (isLoading) {
    diff = 0
  }
  else {
    diff = new Date(data[data.length - 1]?._id) - new Date(data[0]?._id)
    if (diff > 1000 * 60 * 60 * 24 * 7) {
      format = "day"
    } else if (diff > 1000 * 60 * 60 * 24) {
      format = "daytime"
    }
    else {
      format = "time"
    }
  }


  let alias
  period = AVAILABLE_PERIODS[period].value
  if (period === "1H") {
    alias = "Conf. time"
  } else if (period === "1D") {
    alias = "Conf. time (Avg. 10m)"
  } else if (period === "7D") {
    alias = "Conf. time (Avg. 1h)"
  } else if (period === "1M") {
    alias = "Conf. time (Avg. 4H)"
  } else {
    alias = "Conf. time (Avg. 1d)"
  }

  console.log({ alias });

  const config = {
    // data: [data, blocks],
    animation: false,
    height: width > 768 ? 300 : 200,
    data: (isLoading) ? [[], []] : [data, []],
    padding: 'auto',
    xField: '_id',
    yField: ['ts', 'value'],
    // slider: {
    //   start: 0,
    //   end: 1,
    // },
    meta: {
      ['ts']: {
        alias: alias,
        formatter: (v) => {
          if (!Number.isFinite(v)) {
            return "N/A"
          }
          return prettyMilliseconds(v)
        },
        sync: true,
      },
      _id: {
        alias: 'Date',
        formatter: (date) => {
          if (format === "day") {
            return new Date(date).toLocaleDateString()
          }
          else if (format === "daytime") {
            return new Date(date).toLocaleString()
          }
          else {
            return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          }
        },
      },
    },
    yAxis: {
      ts: {
        type: scale,
        // base: 10,
        min: 250,
      },
      bps: {
        type: scale,
        base: 10,
        min: 0,
      },
      // tickCount: 10,
    },


    geometryOptions: [
      {
        geometry: 'line',
        smooth: true,

      },
      {
        geometry: 'line',
        smooth: true,
        seriesField: 'type',
      },

    ],
    nice: true,

  };

  return (
    <>
      <div style={{ maxHeight: 300, marginBottom: 16 }}>
        <DualAxes  {...config} loading={isLoading}  />
      </div>
      <div style={{ marginTop: 8, marginBottom: 16 }}>

        <Segmented onChange={(value) => {
          setScale(value)
        }} value={scale} options={[{
          label: 'Linear', value: 'linear'
        }, { label: 'Log', value: "log" }]} />

      </div>
      {/* {
        period === "all" && <Tooltip placement='topRight' title=<span>
          Min conf. time Recorded by nanospeed on {new Date("2023-09-07T00:00:00.000Z").toLocaleString()}</span>
        >
          <Statistic style={{ float: "right", marginBottom: 8 }} title="Lowest conf. time" value={prettyMilliseconds(171)} />
        </Tooltip>
      } */}
    </>

  );
}

export default ConfTimesChart;