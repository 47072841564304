import React from 'react'
import { msFormatter } from '../../utils'
import prettyMilliseconds from 'pretty-ms'
import { Button, Card, Col, Divider, Row, Skeleton, Space, Typography } from 'antd'
import useSWR from 'swr'
import { ShareAltOutlined } from '@ant-design/icons'
import { Descriptions } from 'antd';

import { Table } from 'antd';


const TableVs = ({ isLoading, avgBtc, energyNano1tx, energyBtc1tx, conf, btcFees, btcTotalFees }) => {
  let totalFeesPerTxBtc = 0
  const kwhPerMile = 0.28 // https://www.tesla.com/charging-calculator
  const kwhPerKm = kwhPerMile / 1.609 
  if (btcTotalFees) {
    totalFeesPerTxBtc = btcTotalFees / (5 * 60 * 60 * 24) // 5tx/s during 24h
  }
  const columns = [
    {
      title: '',
      dataIndex: 'metric',
      key: 'metric',
    },
    {
      title: <div style={{ textAlign: "center" }}>
        <img src="https://nanswap.com/logo/XNO.svg" width={32} height={32} />
      </div>,
      dataIndex: 'xno',
      key: 'xno',
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>
    },
    {
      title: <div style={{ textAlign: "center" }}>
        <img src="https://content-api.changenow.io/uploads/btc_1_527dc9ec3c.svg" width={32} height={32} />
      </div>,
      dataIndex: 'btc',
      key: 'btc',
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>
    },
    // {
    //   title: 'XNO is',
    //   dataIndex: 'xnoIs',
    //   key: 'xnoIs',
    // },
  ];

  const data = [
    {
      key: '1',
      metric: <div>Avg. Confirmation Time <small><a href="#1">(1)</a></small></div>,
      xno: conf.XNO,
      btc: conf.BTC,
      xnoIs: `1054x faster `,
    },
    {
      key: 'energyPerTx',
      metric: <div>Energy per transaction <small><a href="#2">(2)</a></small></div>,
      xno: <div>
        {energyNano1tx / 1000} kWh<br/>
        <small>
        (eq. {(+((energyNano1tx *100) / kwhPerKm)).toLocaleString(undefined, {maximumFractionDigits: 0})}cm with a Tesla Model S)
        </small>
      </div>,
      btc: <div>
        {energyBtc1tx / 1000} kWh<br/>
        <small>
          (eq. {(+((energyBtc1tx /1000) / kwhPerKm)).toLocaleString(undefined, {maximumFractionDigits: 0})}km with a Tesla Model S)
        </small>
      </div>,
      xnoIs: `${+(energyBtc1tx / energyNano1tx).toPrecision(3)}x more energy efficient`,
    },
    {
      key: '3',
      metric: 'Average transaction fees',
      xno: '$ 0',
      btc: "$" + btcFees,
    },
    {
      key: '3',
      metric: <div>Fees total 24h <small><a href="#3">(3)</a></small></div>,
      xno: '$ 0',
      btc: ` $ ${btcTotalFees.toLocaleString('en-GB', { maximumFractionDigits: 0 })}`,
    },
  ];

  return <div style={{margin: 0}}>
    <Table columns={columns} dataSource={data} pagination={false} 
    scroll={{ x: true }}
    />
  </div>
};

function VsBTC() {
  const { data, error, isLoading } = useSWR(`/conf-times?period=all`)
  const { data: dataBtc, error: errorBtc, isLoading: isLoadingBtc } = useSWR(`/btc-stats`)

  const energyBtc1tx = dataBtc?.electricity[0]?.['Output_kWh'] * 1000  //https://digiconomist.net/api-documentation/

  const energyNano1tx = 0.0471 //https://www.reddit.com/r/nanocurrency/comments/10xqv9h/comment/j7tr7wv/?utm_source=share&utm_medium=web2x&context=3

  const avgBtc = dataBtc ? (dataBtc.avgConf * 60 * 1000) + (3 * 60 * 10 * 1000) : 0 // https://www.blockchain.com/fr/explorer/charts/avg-confirmation-time + 3 blocks conf time (kraken)

  if (error) return <div>failed to load</div>
  if (isLoading || isLoadingBtc) return <Skeleton active />

  let tsLast = null

  for (let i = data.length - 1; i >= 0; i--) { // get last non null ts
    if (data[i].ts !== null) {
      tsLast = data[i].ts 
      break
    }
  }
  
  return (
<>
      <Row style={{ marginBottom: 32, 
       }}>
        <Col sm={24} md={24} lg={24}>
          <Card title="Nano vs Bitcoin - Last 24H" bodyStyle={{padding: 8}} >
            <TableVs isLoading={isLoading} avgBtc={avgBtc} energyNano1tx={energyNano1tx} energyBtc1tx={energyBtc1tx} conf={{
              "XNO": 
              tsLast === null ? "N/A" : prettyMilliseconds(tsLast),
              "BTC": prettyMilliseconds(avgBtc, { compact: false })
            }}
            btcFees={dataBtc?.fee.toFixed(2)}
            btcTotalFees={dataBtc?.totalFees}
            />
            <div style={{ marginTop: 24 }}>
              Nano is:
            </div>
            <Space>

              <ul>
                {
                  tsLast !== null &&
                <li>{(+(avgBtc / tsLast).toPrecision(3)).toLocaleString()}x faster </li>
                }
                {/* <li>{+((14*60*1000) / tsLast).toPrecision(3)}x faster than Ethereum</li> */}
                <li>{(+(energyBtc1tx / energyNano1tx).toPrecision(5)).toLocaleString()}x more energy efficient </li>
                <li>Infinitely cheaper to use
                </li>
              </ul>
              <Button size='middle' type='dashed' style={{ marginLeft: 16, marginBottom: 8, marginTop: 8 }} icon={<ShareAltOutlined />}
                onClick={() => navigator.share({
                  title: "Nanospeed",
                  text: `$XNO is ${(+(avgBtc / tsLast).toPrecision(3)).toLocaleString()}x faster than $BTC, ${(+(energyBtc1tx / energyNano1tx).toPrecision(5)).toLocaleString()}x more energy efficient and infinitely cheaper to use. #nanospeed`, url: "https://nanospeed.info/faq#xno-vs-btc"
                })} >Share</Button>
            </Space>
            <p>
              Moreover, Nano's <a href="https://docs.nano.org/glossary/#open-representative-voting-orv" target="_blank"> ORV</a> consensus does not suffer from <a target='_blank' href="https://medium.com/@clemahieu/emergent-centralization-due-to-economies-of-scale-83cc85a7cbef">emergent centralization due to economies of scale</a>
              , unlike Bitcoin's PoW.

            </p>
            <Divider />
            <small>
              <u> Sources as of {new Date().toLocaleDateString()}:</u><br />
              <div id='1'>
                [1]
                Nano: {
                  tsLast === null ? "N/A" :
                prettyMilliseconds(tsLast)} (<a href="https://nanospeed.info" target="_blank">nanospeed.info</a> Avg. 1d)
                BTC: {prettyMilliseconds(avgBtc, { compact: false })} (<a href="https://www.blockchain.com/charts/median-confirmation-time" target="_blank">blockchain.com - Avg. Conf. Time </a> + 3x10m blocks).
              </div>
              <div id='2'>
                [2]
                Nano: {energyNano1tx / 1000} kWh (<a href="https://www.reddit.com/r/nanocurrency/comments/10xqv9h/comment/j7tr7wv" target="_blank">estimate</a>)
                BTC: {energyBtc1tx / 1000} kWh (<a href="https://digiconomist.net/api-documentation/" target="_blank">digiconomist.net API</a>). Equivalent based on Tesla Model S consumption from <a href="https://www.tesla.com/charging-calculator" target="_blank">tesla.com/charging-calculator</a>.
              </div>
              <div id='3'>
                [3]
                Nano: 0 fee (<a href="https://docs.nano.org/whitepaper/english/#introduction" target="_blank">nano.org</a>)
                BTC:
                ${dataBtc?.fee.toFixed(2)} fee per tx (<a href="https://www.blockchain.com/explorer/charts/fees-usd-per-transaction" target="_blank">blockchain.com - Fees Per Transaction</a>)
                &
                ${dataBtc?.totalFees.toLocaleString('en-GB', { maximumFractionDigits: 0 })} Fees paid to miners (<a href="https://www.blockchain.com/explorer/charts/miners-revenue" target="_blank">blockchain.com - Miners Revenue</a>)
              </div>
            </small>
          </Card>
        </Col>
      </Row>
                </>
  )
}

export default VsBTC
