import { Col, Row, Tooltip } from 'antd'
import React from 'react'
import { Statistic } from 'antd';
import prettyMilliseconds from 'pretty-ms';
import useSWR from 'swr'

const { Countdown } = Statistic;

function StatsHistorical({hideMax, hideCountdown}) {
    const { data, error, isLoading } = useSWR(`/max`)
    let benchmarkDate = new Date();
    // benchmarkDate.setHours(benchmarkDate.getHours() -24)

    // benchmarkDate.setHours(9, 24, 0, 0) // 9:24 AM
    benchmarkDate.setUTCHours(7, 23, 10, 0) // 9:24 AM 
    if (benchmarkDate < new Date()) { // if it's past 9:24 AM, set it to tomorrow
        benchmarkDate.setDate(benchmarkDate.getDate() + 1);
    }
    
    // benchmarkDate.setHours(9, 22, 50, 0) // 9:24 AM
    console.log(benchmarkDate)


    return (
        <Row justify={"end"} style={{marginTop: 8}}>
        {
            !hideCountdown  && 
                <Tooltip placement='topRight' title=<span>
                    A daily benchmark is done
                    at {benchmarkDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} by <a href="https://tps.bnano.info/">bnano.info </a>
                    with 7,500 blocks sent in few seconds.
                </span> > 
                {/*  <div >
                    <Countdown title="7,500 blocks speedtest in" value={benchmarkDate} format="HH[h] mm[m] ss[s]" />
                </div> */}
                </Tooltip>
        }
                {!hideMax && <>

                <Tooltip placement='topRight' title=<span>MAX CPS Recorded by nanospeed on {new Date(data?.cps.createdAt).toLocaleString()}</span> >
                <Col span={6} style={{ textAlign: "right" }}>
                    {/* div to get the tooltip work on mobile */}
                    <Statistic title="MAX CPS" value={data?.cps.value} loading={isLoading} />
                </Col>
            </Tooltip>
                <Tooltip placement='topRight' title=<span>
                    MAX BPS Recorded by nanospeed on {new Date(data?.bps.createdAt).toLocaleString()}
                </span> >
            <Col span={6} style={{ textAlign: "right" }}>
                    <Statistic title="MAX BPS" value={data?.bps.value} loading={isLoading} />
            </Col>
                </Tooltip>
                </>
                }
                {/* <Tooltip placement='topRight' title=<span>
                    Min conf. time Recorded by nanospeed on {new Date("2023-09-07T00:00:00.000Z").toLocaleString()}
                </span> >
            <Col span={6} style={{ textAlign: "right" }}>
                    <Statistic title="Min Conf. Time" value={prettyMilliseconds(171)} />
            </Col>
                </Tooltip> */}
        </Row>
    )
}

export default StatsHistorical
