import logo from './logo.svg';

import { Alert, Badge, Button, Card, Col, DatePicker, Divider, Popover, Row, Segmented, Skeleton, Space, Statistic, Tabs, Tooltip, Typography } from 'antd';
import ConfTimesChart from './components/ConfTimes';
import { useCallback, useEffect, useState } from 'react';
import ConfViolin from './components/ViolinPlot';
import LastTxConf from './components/LastTxConf';
import GaugeCPS from './components/GaugeCPS';
import FAQ from './components/FAQ';
import { Liquid } from '@ant-design/charts';
import BoxPlot from './components/BoxPlot';
import { AVAILABLE_PERIODS, fetcherWS, getMsColor, msFormatter, useWindowDimensions } from './utils';
import prettyMilliseconds from 'pretty-ms';
import Appx from './components/MainTabs';
import Percentiles from './components/main/Percentiles';
import VsBTC from './components/main/VsBTC';
import { PercentageOutlined, BoxPlotOutlined, LineChartOutlined, ShareAltOutlined, TableOutlined, FieldTimeOutlined, DashboardOutlined ,
  QuestionCircleOutlined
} from '@ant-design/icons'
import StatsLive from './components/StatsLive';
import useSWR, { preload } from 'swr'
import TableConfs from './components/main/TableConfs';
import CPSBPSChart from './components/CPSBPSChart';
const { Countdown } = Statistic;

function App() {

  // const [data, setData] = useState(null);
  const [dataBox, setDataBox] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [period, setPeriod] = useState("7D")
  const [scale, setScale] = useState("log")
  const [chartType, setChartType] = useState("line")
  const [tabs, setTabs] = useState("conftime")
  const [subTabs, setSubTabs] = useState("1")
  const [startDate, setStartDate] = useState(new Date())
  const [timeFirstConfirmation, setTimeFirstConfirmation] = useState(400)
  const [cps, setCPS] = useState(null);
  const [bps, setBPS] = useState(null);
  const [wsState, setWsState] = useState(WebSocket.CONNECTING);
  const [periodLive, setPeriodLive] = useState("Live")
  const [isLive, setIsLive] = useState(true)
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    const pathname = window.location.pathname
    console.log(pathname)
    console.log(pathname.split("/"))
    // ?period=1H
    const searchParams = new URLSearchParams(window.location.search);
    const periodParam = searchParams.get("period");
    if (periodParam !== undefined){
      console.log(periodParam)
      let value = Object.keys(AVAILABLE_PERIODS).find((p) => AVAILABLE_PERIODS[p].value === periodParam)
      if (value !== undefined){
        setPeriod(value )
        setPeriodLive(value )
        setIsLive(false)
      }
    }
    if (pathname === "/faq"){ // redirect old faq
      setTabs("about") 
      window.history.pushState({}, "", `/about`)
    }
    else{
      setTabs(pathname === "/" ? "conftime" : pathname.split("/")[1])
    }
    preload(`/last`, fetcherWS)
  }, [])

  const Periods = () => (<Segmented  size='middle' onChange={(value) => {
    console.log(value)
    setPeriod(value)
    setPeriodLive(value)
    setIsLive(false)
  }}
    value={period}
    options={Object.keys(AVAILABLE_PERIODS).filter((p) => p !== "Last 10 Minutes")}
    style={(subTabs === "4" || (tabs === "cpsbps")) ? { display: "none" } : {marginBottom: 16, marginTop: -8}}
  />)
  const subItems = [
    {
      key: '1',
      label: <span>
        <PercentageOutlined />
      </span>,
      children: <div style={{ height: "100%" }}>
        <Row justify={"space-evenly"} gutter={32}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Percentiles period={period} />
          </Col>
          <Col xs={0} sm={0} md={12} lg={8} xl={8}><Percentiles period={"1M"} /></Col>
          <Col xs={0} sm={0} md={0} lg={8} xl={8}><Percentiles period={"All"} /></Col>
        </Row>
          <ConfTimesChart period={period} scale={scale} setScale={setScale} />
          <Periods />
          <CPSBPSChart isTabActive={
            tabs === "cpsbps" || tabs === "conftime"
          } setIsLive={setIsLive} isLive={isLive} period={period} setPeriod={setPeriod}  cps={cps} periodLive={periodLive} setPeriodLive={setPeriodLive} />
          <TableConfs />
        <div />
      </div>
    },
    // {
    //   key: '2',
    //   label: <span>
    //     <LineChartOutlined />
    //   </span>,
    //   children: <>

    //     <ConfTimesChart period={period} scale={scale} setScale={setScale} /> <Periods />
    //   </>
    // },
    {
      key: '3',
      label: <span>
        <BoxPlotOutlined />
      </span>,
      children: <><BoxPlot period={period} scale={scale} setScale={setScale} /><Periods />
      </>
    },
    // {
    //   key: '4',
    //   label: <span>
    //     <TableOutlined />
    //   </span>,
    //   children: <><TableConfs /></>
    // }
  ]

  const itemsLineChartOutlined = [
    {
      key: 'conftime',
      label: <span><FieldTimeOutlined />
        Confirmation times </span>,
      children: <>
        <Tabs type='card' defaultActiveKey="conftime" onChange={setSubTabs} items={subItems} activeKey={subTabs}/>
      </>
    },
    // {
    //   key: 'cpsbps',
    //   label: <span><DashboardOutlined />
    //     CPS</span>,
    //   children: <>
    //     <CPSBPSChart isTabActive={
    //       tabs === "cpsbps" || tabs === "conftime"
    //     } setIsLive={setIsLive} isLive={isLive} period={period} setPeriod={setPeriod} scale={scale} setScale={setScale} cps={cps} periodLive={periodLive} setPeriodLive={setPeriodLive} />
    //   </>,
    // },
    {
      key: 'about',
      label: <Row>
        <Col>
          <QuestionCircleOutlined />About
        </Col>
        </Row>,
      children: <>
        <FAQ />
      </>,
      forceRender: true,
    },
  ]


  return (
    <div className="App">

      <div style={{ margin: 8 }}>

        <Row>
          <Col xs={24} sm={24} md={{
            span: 9,
            offset: 3
          }} lg={{
            span: 8,
            offset: 4
          }}
          >
            <div style={{display: "flex", cursor: "pointer"}} onClick={() => {
              // sort of return to home
              setTabs('conftime')
              setSubTabs('1') 
              window.history.pushState({}, "", `/`)
            }}>
            <div style={{}} ><img src="https://nanswap.com/logo/XNO.svg" width={80} alt='nano_logo' style={{marginTop: 28, marginRight: 8}}/></div>
            <div style={{marginLeft: 8}}>
              <Typography.Title style={{ color: "white", marginBottom: 8, marginTop: 24 }}>Nanospeed
              </Typography.Title>
              <Typography.Title level={3} style={{ color: "white", marginTop: 0 }}>Nano Monitoring Tool</Typography.Title>
            </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>

            {/* <Card style={{textAlign: "left"}}> */}
            {/* <LastTxConf data={data}  /> */}
            {/* </Card> */}
            {/* <Row style={{textAlign: "center"}}>
        <Col span={12} >
          <GaugeCPS />
        </Col>
        <Col span={12}>
          <GaugeCPS />
        </Col>
        </Row> */}
            <div style={{ textAlign: "center", }}>

              <Card style={{ marginLeft: "auto" }}>

                <Typography.Title level={3} style={{ textAlign: "left", marginTop: 0 }}>Live
                </Typography.Title>
                <StatsLive cps={cps} bps={bps} timeFirstConfirmation={timeFirstConfirmation} wsState={wsState} />
              </Card>
            </div>
            <a target='_blank' href='https://nanswap.com/nano-faucet?r=nanospeed'>

        <Alert style={{ marginTop: 8, textAlign: "center" }} message={<>
       Get some nano for free to test its speed at <a target='_blank' href='https://nanswap.com/nano-faucet?r=nanospeed'>nanswap.com/nano-faucet</a></>} type="info" />
            </a>
          </Col>
        </Row>

        <Card style={{ marginTop: 8 }} title={
          // width < 992 ? "Historical" : ""
          ""
        }>
          <Row gutter={32} style={{}}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Tabs size='small'  style={{ height: "100%" }} type='line' defaultActiveKey={
"conftimes"
                } items={itemsLineChartOutlined} onChange={(e) => {
                  setTabs(e)
                  // set url 
                  if (e === "conftime") {
                    window.history.pushState({}, "", `/`)
                  }
                  else {
                    window.history.pushState({}, "", `/${e}`) 
                  }
                  }}
                  activeKey={tabs} />

            </Col>
            {/* <Col xs={0} sm={0} md={0} lg={12} xl={24}>
              <Card style={{ height: "100%" }} extra={<Space><FieldTimeOutlined />Confirmation times </Space>} title="Historical">
                <Tabs tabPosition='top' type='line' defaultActiveKey="1" items={subItems} onChange={(e) => setTabs(e)} destroyInactiveTabPane />
              </Card>
            </Col> */}
           
          </Row>
        </Card>
        <Row>
        {
          tabs === "about" && 
       <Col span={24} style={{textAlign: "right", marginTop: 16, marginBottom: 0}}>
          <div style={{color: "white", }}>{"More stats: "}
          <a style={{color: "white", textDecoration: "underline"}} href='https://stats.nanobrowse.com/' target='_blank'>NanoTicker</a>
          {", "}
          <a style={{color: "white", textDecoration: "underline"}} href="https://nanexplorer.com/nano" target='_blank'>Nano Explorer</a>
          </div>
       </Col>
        }
        </Row>
          <Typography.Paragraph style={{ fontSize: 14, marginBottom: 32, marginTop: 32, textAlign: "center" }}>
          <a href="https://twitter.com/nanospeedInfo" target="_blank">
               <img style={{borderRadius: 8}} src={'/twitter.ico'} width={32} />
          </a>
          </Typography.Paragraph>
        {/* <iframe src="https://nanswap.com/nano-faucet" style={{width: "100%", height: 500, border: "none", marginTop: 32}}></iframe> */}
        {/* <ConfViolin data={data} /> */}
      
      </div>
    </div>
  );
}

export default App;
