import { Badge, Col, Row, Statistic, Tooltip } from 'antd'
import prettyMilliseconds from 'pretty-ms';
import React, { useCallback, useEffect, useState } from 'react'
import CountUp from 'react-countup';
import { getMsColor } from '../utils';
import useWebSocket from 'react-use-websocket';
import WsBadgeState from './WsBadgeState';
import useSWR from 'swr';




function StatsLive() {
  const {data, error, isLoading} = useSWR('/blocks?period=1H', {refreshInterval: 1000 * 60}) // refresh every 1m
  let lastRateCPS = 0
  if (data !== undefined){
    // keep only last 10 elements
    lastRateCPS = data.slice(-10)
    lastRateCPS = lastRateCPS.reduce((a, b) => a + b.cps, 0) / lastRateCPS.length // avg cps of last hour
  }


  const [timeFirstConfirmation, setTimeFirstConfirmation] = useState(null)
  const [cps, setCPS] = useState(null);
  const [bps, setBPS] = useState(null);
  const [backlog, setBacklog] = useState(null);
  const formatterCPS = (value) => <CountUp preserveValue={true} end={value} decimals={0} duration={0.5} useEasing={false} />;
  const formatterConf = (value) => <CountUp preserveValue={true} end={value} start={500} formattingFn={memoizedCallback} duration={2} useEasing={true} startOnMount={false} />;
  const memoizedCallback = useCallback((value) => prettyMilliseconds(value), []);
  const {sendMessage, lastMessage, readyState} = useWebSocket(process.env.REACT_APP_WS, {
    onMessage: function (event) {
      let data = JSON.parse(event.data)
      if (data['lastConfTime'] !== undefined) {
        setTimeFirstConfirmation(data['lastConfTime'])
      }
      let delta = 0
      if (data['cps'] !== undefined) {
        setCPS(data['cps'])
        delta -= data['cps']
      }
      if (data['bps'] !== undefined) {
        setBPS(data['bps'])
        delta += data['bps']
      }
      setBacklog((prev) => prev + delta)
      if (data['backlog'] !== undefined) {
        setBacklog(data['backlog'])
      }
      if (cps !== null && timeFirstConfirmation !== null){
        document.title = `${cps} CPS | ${prettyMilliseconds(timeFirstConfirmation)}`
      }
    }, 
    share: true,
    shouldReconnect: (closeEvent) => true,

  })

  useEffect(() => {
  }, [])

  let backlogEstimate = 0
  if (lastRateCPS !== 0){
    backlogEstimate = (backlog / lastRateCPS) * 1000
  }

  return (
    <>

      <Row>
      <Tooltip title="Confirmation time based on the last block sent by nanospeed">
      <Col
      xs={24}
       sm={24} md={6}
       style={{ textAlign: "center", marginBottom: 16 }}>
          <Statistic title="Confirmation Time" value={
            timeFirstConfirmation !== null ?
            prettyMilliseconds(timeFirstConfirmation)
            : "N/A"
          } precision={0} valueStyle={{
            color: getMsColor(
              timeFirstConfirmation
            )
          }} />
        </Col>
        </Tooltip>
          <Tooltip title="Block Per Second">
        <Col xs={8} sm={8} md={6}
        >
            <Statistic title="BPS" value={bps} suffix="BPS" />
  {/* <Statistic formatter={formatterCPS} title="BPS" value={bps} suffix="BPS" precision={2} /> */}
        </Col>
          </Tooltip>
        
          <Tooltip title="Confirmation Per Second">
        <Col xs={8} sm={8} md={6}>
                <Statistic title="CPS" value={cps} suffix="CPS" />
            {/* <Statistic formatter={formatterCPS} title="CPS" value={cps} suffix="CPS" precision={2} /> */}
        </Col>
          </Tooltip>
          <Tooltip title={`Number of unconfirmed blocks & estimated time to clear based on ${lastRateCPS.toFixed(2)} CPS (avg. of last 10m)`}>
        <Col xs={8} sm={8} md={6}>
                <Statistic title="Backlog" value={backlog} suffix="blocks" />
            {/* <Statistic formatter={formatterCPS} title="CPS" value={cps} suffix="CPS" precision={2} /> */}
            {
              backlogEstimate > 60000 && <>
            (
              ~{
                prettyMilliseconds(backlogEstimate)
                } seconds
            )
              </>
            }
        </Col>
          </Tooltip>
      </Row>
      <div style={{ marginLeft: 8, marginTop: 8, float: "right" }}>
        <WsBadgeState  />
      </div>
    </>
  )
}

export default StatsLive
