import { Card, Col, Row, Statistic, Tooltip, Typography } from 'antd'
import prettyMilliseconds from 'pretty-ms';
import React, { useEffect, useState } from 'react'
import { getMsColor } from '../utils';
import GaugeCPS from './GaugeCPS';

const asc = arr => arr.sort((a, b) => a - b);
const sum = arr => arr.reduce((a, b) => a + b, 0);


const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

function LastTxConf({data}) {
    const [stats, setStats] = useState({
        "avg": null,
        "percentile": {
            "50": null,
            "90": null,
            "99": null
        }
      });

    const network_state = {
        "750": {
            name: "normal"
        },
        "1000": {
            name: "normal"
        }
    }

    useEffect(() => {
        console.log(data);
        if (data === undefined || data.length === 0 ) return
        let arr = data.map((a) => a.timeFirstConfirmation)
          const mean = arr => sum(arr) / arr.length;
          console.log(mean(arr));
    
          setStats({
            avg: mean(arr),
            percentile: {
                "50": quantile(arr, .50),
                "90": quantile(arr, .90),
                "99": quantile(arr, .99),
            }
        })

    }, [data])
    

    if (data === undefined || data.length === 0) return "Loading last tx..."
  return (
    <>

    
    <Typography.Title style={{fontSize: 20, textAlign: 'center'}}>
    
    <Tooltip title="Conf time Based on a block sent x seconds ago.">
    {" "} <span style={{fontSize: 24, color: getMsColor(data[data.length-1].timeFirstConfirmation)}}>
    {prettyMilliseconds(data[data.length-1].timeFirstConfirmation)}
    </span>{" "}
    </Tooltip>
    </Typography.Title>
    
    {/* <Card>

    <Row>
        <Col span={8}>
            <Statistic title={<><Tooltip title="Confirmation Per Second monitor the number of block confirmed each second on the network">Latest Conf. time</Tooltip></>} value={data[0].timeFirstConfirmation} suffix="ms"/>
        </Col>
        <Col span={8}>
    <Statistic title="Current CPS" value={data[0].timeFirstConfirmation} suffix="CPS"/>
        </Col>
        <Col span={8}>
    <Statistic title="Current BPS" value={data[0].timeFirstConfirmation} suffix="BPS"/>
        </Col>
    </Row>
    </Card> */}

    {/* link to nanoticker */}
    {/* <p>Result based on the confirmation time of <a>the latest nanospeed transaction</a>. -> table last confs</p> */}
    {/* <div style={{textAlign: "left"}}>
    <pre>
        <code>{JSON.stringify(stats, null, 2)}</code>
    </pre>
    </div> */}
    </>
  )
}

export default LastTxConf