import { Button, Card, Pagination, Segmented, Skeleton, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { AVAILABLE_PERIODS, fetcher, msFormatter } from '../../utils'
import useSWR, {preload} from 'swr'
import { ShareAltOutlined } from '@ant-design/icons'

function TableConfs({period}) {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, error, isLoading } = useSWR(`/conf-times-table?page=${pageIndex}&limit=${pageSize}`, fetcher)
  
  useEffect(() => {
    preload(`/conf-times-table?page=${pageIndex+1}&limit=${pageSize}`, fetcher)
    preload(`/conf-times-table?page=${pageIndex+2}&limit=${pageSize}`, fetcher)
  }, [pageIndex, pageSize])

  const columns = [
    {
      title: 'Conf. Time',
      dataIndex: 'timeFirstConfirmation',
      render: (text) => msFormatter(text)
    },
    {
      title: "Sent date",
      dataIndex: 'createdAt',
      render: (text) => new Date(text).toLocaleString()
    },
    {
      title: 'Hash',
      dataIndex: 'hash',
      render: (text) => <a href={`https://nanexplorer.com/nano/block/${text}`} target="_blank">{text?.slice(0, 6)}...</a>
    }
  ]
  if (error) return <div>Failed to load. Please try again in few seconds.</div>
  if (isLoading) return <Table loading />
  
  return <>
    <Table dataSource={data.docs} loading={isLoading} rowKey="_id" pagination={false} scroll={{ x: true }} columns={columns} size="small"/>
    <Pagination style={{marginTop: 16, marginBottom: 8}} size='small' total={data.totalDocs} pageSize={data.limit} current={data.page} onChange={(page, pageSize) => {
      setPageIndex(page)
      setPageSize(pageSize)
    }} showQuickJumper nex
    />

  </>
  
}
export default TableConfs