import { Line } from '@ant-design/charts';
import { Button, Row, Space } from 'antd';
import { isEqual } from 'lodash-es';
import React, { memo, useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { fetcherWS, useWindowDimensions } from '../../utils';
import useSWR, {preload} from 'swr'
import StatsHistorical from './StatsHistorical';
import WsBadgeState from '../WsBadgeState';

const LiveChart = memo (({paused, scale, isLoading, data = [], sliderShown}) => {
  const { height, width } = useWindowDimensions();

  console.log(`rendering live chart ${data.length} elmnts`)
  // console.log(data)
  // return <div>Loading</div>
  // let maxValue = 10
  // if (data.length > 0) {
    // let maxValueData = data.reduce((a, b) => Math.max(a, b.value), 0)
    // if (maxValueData > maxValue) {
      // maxValue = maxValueData
    // }
  // }

  const config = {
    // data: [data, blocks],
    animation: false,
    height: width > 768 ? 300 : 200,
    data: isLoading ? [] : data,
    padding: 'auto',
    xField: 'ts',
    yField: 'value',
    seriesField: 'cat',
   
    meta: {
      ts: {
        alias: 'Date',
        formatter: (date) => {
            return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        },
      },
      cat: {
        formatter:(arg)=> {
          if (typeof arg !== 'string') return arg
          return arg.toUpperCase()
       },
       
      },

    },
   
    yAxis: {
      type: scale,
      },
    geometryOptions: [
      {
        smooth: true,
        
      },
      {
        smooth: true,
      },
    ],
    stepType: 'vh',
    limitInPlot: false,
    slider: !sliderShown ? false : {
      start: 0.8,
      end: 1,
    },
  };
  return <><Line  {...config}   />
   
    </>
}, (prevProps, nextProps) => {
  if (prevProps.scale !== nextProps.scale) {
    return false
  }
  if (prevProps.paused === false && nextProps.paused === true) {
    return false
  }
  if (isEqual(prevProps.data, nextProps.data)) {
    return true
  }
  if (prevProps.paused === true && nextProps.paused === true) {
    return true
  }
  else {
    return false
  }


})



function CPSBPSLiveChart({scale, paused, setPaused, isTabActive}) {
  const [sliderShown, setSliderShown] = useState(false)
  const [cpsbpslive, setCPSBPSLive] = useState([]);
  const [data, setData] = useState([])
  const [dataBeforePause, setDataBeforePause] = useState([])
  const { data: last, error, isLoading } = useSWR(`/last`, fetcherWS, {
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
    
  useEffect(() => {
    if (last !== undefined && !isLoading) {
      setData(last)
      if (!paused) {
        setDataBeforePause(last)
      }
    }
  }, [ isLoading, last])

 
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(process.env.REACT_APP_WS, {
    onOpen: () => console.log('opened'),
    onMessage: (event) => {
      if (!isTabActive) return // optimization: do not update if tab is not active
      let dataWS = JSON.parse(event.data)
      
      if (dataWS['bps'] !== undefined && dataWS['cps'] !== undefined){
        let ts = new Date().getTime()
        let parsed = []
        parsed.push({ ts: ts, value: dataWS['bps'], cat: "bps" })
        parsed.push({ ts: ts, value: dataWS['cps'] , cat: "cps" })
        let newData
        if (data.length > 60){ // 15 min (2xrows )
          newData = [...data.slice(2), ...parsed]
        }
        else {
          newData = [...data, ...parsed]
        }
        setData(newData)
        if (!paused) {
          setDataBeforePause(newData)
        }
        }
    },
    share: true,

  });

  return (
    <>
<div style={{ height: 300, marginBottom: 16 }}>
    <LiveChart scale={scale}  data={dataBeforePause} paused={paused} isLoading={false}  sliderShown={sliderShown}
    />
    </div>

    <Button style={{marginBottom: 0, marginLeft: 128}} 
     onClick={() => {
       setPaused(!paused)
      setSliderShown(!sliderShown)
      }} 
     icon={
      paused ? <PlayCircleOutlined /> :  <PauseCircleOutlined />
    }
    >
    {paused ? "Resume" : "Pause"}
    </Button>
    <span style={{ marginTop: 12, float: "right" }}>
      <WsBadgeState />
    </span>
    </>

  )
}

export default CPSBPSLiveChart