import { Button, Card, List, Segmented, Skeleton, Statistic, Typography } from 'antd'
import React from 'react'
import { AVAILABLE_PERIODS, getMsColor, msFormatter } from '../../utils'
import useSWR from 'swr'
import { ShareAltOutlined } from '@ant-design/icons'
import Paragraph from 'antd/es/typography/Paragraph'
import prettyMilliseconds from 'pretty-ms'

function Percentiles({period}) {
  const startDate = "December 8, 2022 16:20:00"
  const { data, error, isLoading } = useSWR(`/conf-times-box-global?period=${AVAILABLE_PERIODS[period].value}`)
  if (error) return <div>Failed to load. Please try again in few seconds.</div>
  const periodText = period === "All" ? `Since ${new Date(startDate).toLocaleDateString()}` : `In the last ${AVAILABLE_PERIODS[period].label}`

  return <><div           
>
  <Typography.Title level={5} style={{marginTop: 8}}>
          {periodText}:
  </Typography.Title>
          <List
          loading={isLoading}
          bordered
    >
            <List.Item style={{fontSize: 16}} >50% of transactions were confirmed within {!isLoading && msFormatter(data.median)}</List.Item>
            <List.Item style={{fontSize: 16}}> 90%  within {!isLoading && msFormatter(data.q90)}</List.Item>
            <List.Item style={{fontSize: 16}}> 99%  within {!isLoading && msFormatter(data.q99)}</List.Item>
            {/* {(period === "1 Month" || period === "All") && <List.Item style={{fontSize: 16}}> 99.9%  within {!isLoading && msFormatter(data.q999)}</List.Item>} */}
            <List.Item style={{fontSize: 16}}> 100% within {!isLoading && msFormatter(data.max)}</List.Item>
    </List>
          <Button size='middle' type='dashed' style={{ float: "right", marginRight: 8, marginBottom: 8, marginTop: 8 }} icon={<ShareAltOutlined />}
 onClick={() => navigator.share({
title: "Nano Speed", 
text: `${periodText}, 99% of transactions on $XNO were confirmed within ${!isLoading && prettyMilliseconds(data.q99)}. #nanospeed`, url: "https://nanospeed.info/"})} >Share</Button>    
        </div>
    
</>
}

export default Percentiles