import { Card, Col, Collapse, Divider, Row, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import VsBTC from './main/VsBTC';
import useSWR from 'swr'
const { Panel } = Collapse;

function FAQ() {
  const { data, error, isLoading } = useSWR(`/max`)
  const [activeKey, setActiveKey] = React.useState([])
  useEffect(() => {
    // set default active key with url hash
    if (window.location.hash) {
      // set all  active keys with url hash
      setActiveKey([...window.location.hash.split("#")[1].split(',')])
    }
  } , [])

  return (
    <div style={{marginTop: 16}}>
     <Row style={{flexDirection: "row-reverse"}} >
        <Col xs={24} sm={24} md={12} style={{marginBottom: 16}}>
    <Collapse defaultActiveKey={["5"]} activeKey={activeKey} ghost onChange={
      (e) => {
        setActiveKey(e)
        window.history.pushState({}, "", `#${e}`)
      } 
    }>
      <Panel forceRender header="What is Confirmation Time ?" key="what-is-conf-time">
        <p>
          Confirmation time is the duration it takes for a transaction to be confirmed on the network. <br/>
          This can be used to estimate how long it will take for your transactions to be confirmed on nano, which is especially useful when the network is congested.<br/>
          <br/>
          Here, this number is calculated by sending a block from nanospeed's nano node every minutes, and measuring the time it takes for the block to be seen as confirmed by the network. <br/>
          Given that the same account is used every time, nanospeed transactions should be deprioritized by the network, confirmation time indicated here can therefore be considered as the worst possible case.<br/>
          Please note that all the stats are only based on nanospeed's transactions & node and may not be representative of the whole network given the asynchronous nature of Nano.
        </p>
      </Panel>
      <Panel forceRender header="What is CPS ?" key="what-is-cps">
      <p>
  Confirmation Per Second (CPS) is the number of block confirmed on nano each second. <br/>
  When CPS is high it means that nano is used heavily, it becomes more congested and confirmation time of transactions will have good chance of increasing until CPS returns lower.
  </p>
  Max CPS can increase with better network hardware, more efficient nano-node source code and or less <a href="https://docs.nano.org/glossary/#principal-representative">PR Nodes</a>.
  <br/>
  CPS is calculated each second with nanospeed's nano node websocket of confirmed blocks.<br/>
  Max CPS recorded by nanospeed: {isLoading || error ? <Skeleton.Input active size='small' /> : <><b>{data.cps.value} CPS</b> on {new Date(data?.cps.createdAt).toLocaleString()}</>}.
      </Panel>
      <Panel forceRender header="What is BPS ?" key="what-is-bps">
        <p>
          Block Per Second (BPS) is the number of block sent on the network each second. 
          This number is calculated each second with nanospeed's nano node websocket of unconfirmed blocks.         </p>
          Max BPS recorded by nanospeed: {isLoading || error ? <Skeleton.Input active size='small' /> : <><b>{data.bps.value} BPS</b> on {new Date(data?.bps.createdAt).toLocaleString()}</>}.
          
      </Panel>
      <Panel forceRender header="What is the address used by nanospeed ?" key="what-is-nanospeed-address">
        <p>
          <a href="https://nanexplorer.com/nano/account/nano_3ug8jkpbr35qpa1ceyf6kf7za8nirbxyiyh58iapfzrujfsi4dxf4kmbp6nq" target="_blank">nano_3ug8jkpbr35qpa1ceyf6kf7za8nirbxyiyh58iapfzrujfsi4dxf4kmbp6nq</a> is used by nanospeed to send blocks. <br/>
           <br/>
          <br/>
        </p>          
      </Panel>
    </Collapse>
    </Col>
    <Col xs={24} sm={24} md={12}>
        <VsBTC />
    </Col>
     </Row>
    </div>
  )
}

export default FAQ