import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import './App.css';
import { SWRConfig } from 'swr';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <SWRConfig 
      value={{
        fetcher: (resource, init) => fetch(process.env.REACT_APP_API + resource, init).then(res => res.json()).then(res => {
          if (res.error) {
            throw new Error(res.error);
          }
          return res;
        })
      }}
      >
        <App />
      </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
